import React, { useState, useEffect, useCallback } from "react";
import { useConfigBase } from "../../contexts/configBase.context";

// import defaultImg from "../../assets/750x242.jpg";
import { addImgKeyParam, fetchData, imgDecrypt } from "../../utils/utils";

const Image = ({ srcValue = "", alt = "img", className = "", onClick = () => {} , size = false}) => {
  const { configBaseList } = useConfigBase();
  const [imgSrc, setImgSrc] = useState(`${process.env.REACT_APP_LOCAL_IMAGE}/images/750x242.jpg`);

  const handlerOnClick = (value) => {
    onClick(value);
  };

  const fmtimg = useCallback(async () => {
    const imageUrlKey = configBaseList.encrypted_image_domain || "";

    if (srcValue && (srcValue.includes("https://") || srcValue.includes("http://"))) {
      return setImgSrc(srcValue);
    }
    if (imageUrlKey !== "" && srcValue !== "") {
      const vidKeyParam = addImgKeyParam(srcValue);

      const dynamicEncryptUrl = `${imageUrlKey}${srcValue}${vidKeyParam}`;
      return setImgSrc(dynamicEncryptUrl);

      // let encryptUrls = `${imageUrlKey}/${srcValue}.txt`;

      // if(size) {
      //   encryptUrls =  `${encryptUrls}?size=600x337`
      // }
      
      // const res = await fetchData(encryptUrls);
      // let __decrypted = ""

      // if (res) {
      //   __decrypted = res.indexOf("data") >= 0 ? res : imgDecrypt(res);
      //   return setImgSrc(__decrypted);
      // }
    }
  }, [configBaseList.image_url, srcValue]);

  useEffect(() => {
    // setImgSrc(defaultImg);
    fmtimg();
  }, [fmtimg, srcValue]);

  return (
    <img
      src={imgSrc}
      alt={alt}
      className={className}
      onClick={handlerOnClick}
    />
  );
};

export default Image;
