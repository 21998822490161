import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import logo from "../../assets/logo-cili.png";
import logoMain from "../../assets/logo-main.png";
import logoPic from "../../assets/logo-pic.png";
import hamburger from "../../assets/hamburger.png";
import close from "../../assets/close.png";
import menuHome from "../../assets/menu_home.png";
// import arrowBottom from "../../assets/icon-arrow-bottom.svg";
import arrowUp from "../../assets/icon-arrow-top.svg";

import styles from "./Header.module.css";
import { useCategory } from "../../contexts/category.context";
import Image from "../image/Image";
import { useHeader } from "../../contexts/header.context";

const Header = ({ isScroll }) => {
  const navigate = useNavigate();
  const { isMobileHeaderChildOpen, setIsMobileHeaderChildOpen } = useHeader();
  const { categories, setCategorySelected } = useCategory();

  const [menuChildIsShown, setMenuChildIsShown] = useState(false);
  const [sidemenuOpen, setSidemenuOpen] = useState(false);
  const [mainDomainName, setMainDomainName] = useState("/");
  const [picDomainName, setPicDomainName] = useState("/");

  const cssStyle = useMemo(() => {
    const _style = `${styles.headerContainer} ${styles.header}`;
    return isScroll ? `${_style} ${styles.scroll}` : _style;
  }, [isScroll]);

  const handleCategoryClick = (category) => {
    setSidemenuOpen(false);
    setCategorySelected(category);
    navigate("/main");
  };

  const getDomainName = () => {
    const hostname = window.location.origin;
    const domain = hostname.replace("download.", "");
    setMainDomainName(domain);
    const picDomain = hostname.replace("download.", "pic.");
    setPicDomainName(picDomain);
  };

  useEffect(() => {
    getDomainName();
  }, []);

  return (
    <>
      <div className={styles.headerSection}>
        <div className={cssStyle}>
          <div className={styles.headerCard}>
            <div
              className={styles.headerMenuLogo}
              onMouseLeave={() => setMenuChildIsShown(false)}
            >
              <div
                className={styles.headerMenuShow}
                onMouseEnter={() => setMenuChildIsShown(true)}
              >
                <Link to="/main">
                  <img
                    src={logo}
                    alt="logo"
                    className={styles.headerMenuMargin}
                  />
                  {menuChildIsShown ? (
                    <img
                      src={arrowUp}
                      alt="arrow-top"
                      className={styles.arrowIconImg}
                    />
                  ) : (
                    <img
                      src={`${process.env.REACT_APP_LOCAL_IMAGE}/images/icon-arrow-bottom.svg`}
                      alt="arrow-bottom"
                      className={styles.arrowIconImg}
                    />
                  )}
                </Link>
              </div>
              {menuChildIsShown && (
                <div className={styles.headerMenuChild}>
                  <ul>
                    <li>
                      <Link to={mainDomainName} className={styles.otherMenu}>
                        <img src={logoMain} alt="logo" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={picDomainName}
                        className={`${styles.otherMenu} ${styles.otherMenuMain}`}
                      >
                        <img src={logoPic} alt="logo" />
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mobileHeaderSection}>
        <div className={styles.mobileHeaderContainer}>
          <div
            className={`${styles.mobileHeader} ${
              !isMobileHeaderChildOpen && `${styles.mobileHeaderShadow}`
            }`}
          >
            <div className={styles.hamburgerSlide}>
              <img
                width={30}
                height={30}
                src={hamburger}
                alt="hamburger"
                onClick={() => setSidemenuOpen(true)}
              />
            </div>
            <div className={styles.headerCard}>
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
              {isMobileHeaderChildOpen ? (
                <img
                  width={20}
                  height={20}
                  src={arrowUp}
                  alt="arrow-top"
                  className={styles.arrowIconImg}
                  onClick={() => setIsMobileHeaderChildOpen(false)}
                />
              ) : (
                <img
                  width={20}
                  height={20}
                  src={`${process.env.REACT_APP_LOCAL_IMAGE}/images/icon-arrow-bottom.svg`}
                  alt="arrow-bottom"
                  className={styles.arrowIconImg}
                  onClick={() => setIsMobileHeaderChildOpen(true)}
                />
              )}
            </div>
          </div>
          {isMobileHeaderChildOpen && (
            <div className={styles.mobileChildHeader}>
              <div
                className={styles.mobileChildItem}
                style={{ marginLeft: "-5px" }}
              >
                <Link to={mainDomainName}>
                  <img src={logoMain} alt="logo" />
                </Link>
              </div>
              <div className={styles.mobileChildItem}>
                <Link to={picDomainName}>
                  <img src={logoPic} alt="logo" />
                </Link>
              </div>
            </div>
          )}
        </div>
        <div
          className={`${styles.mobileSlideContent} ${
            sidemenuOpen ? styles.open : ""
          }`}
        >
          <div className={styles.sidemenuHeader}>
            <div
              className={styles.closeSidemenu}
              onClick={() => setSidemenuOpen(false)}
            >
              <img width={23} height={23} src={close} alt="close" />
            </div>
            <Link to="/">
              <img width={110} height={20} src={logo} alt="logo" />
            </Link>
          </div>
          <div className={styles.sidemenuCategories}>
            {categories.map((category, index) => {
              return (
                <Image
                  key={index}
                  srcValue={category && category.icon}
                  className={styles.sidemenuCategoriesImage}
                  onClick={() => handleCategoryClick(category)}
                />
              );
            })}
          </div>
        </div>
        <div
          className={`${styles.overlay} ${sidemenuOpen ? styles.open : ""}`}
          onClick={() => setSidemenuOpen(false)}
        ></div>
      </div>
    </>
  );
};

export default Header;
