import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigBaseProvider } from "./contexts/configBase.context";
import { CategoryProvider } from "./contexts/category.context";
import { HeaderProvider } from "./contexts/header.context";

function ctrlShiftKey(e, keyCode) {
  return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
}

// Disable right-click
// document.addEventListener('contextmenu', (e) => e.preventDefault());

document.onkeydown = (e) => {
  console.log('e', e.keyCode)
  // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
  // if (
  //   e.keyCode === 123 ||
  //   ctrlShiftKey(e, 'I') ||
  //   ctrlShiftKey(e, 'J') ||
  //   ctrlShiftKey(e, 'C') ||
  //   (e.altKey && e.keyCode === 73) ||
  //   (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
  // )
  //   return false;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HeaderProvider>
      <ConfigBaseProvider>
        <CategoryProvider>
          <App />
        </CategoryProvider>
      </ConfigBaseProvider>
    </HeaderProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
